import './css'
import logo from 'assets/logo.png'
import { render } from 'preact'
import { Card, Button, H5 } from '@blueprintjs/core'
import { navigate } from 'utils/request'
const Login = () => {
    return (<>
        <div style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>

            <Card interactive >
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 10
                }}>
                    <img src={logo} width={100} height={50} />
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 10
                }}>
                    <H5>I am a</H5>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 10
                }}>
                    <Button icon='label' onClick={() => navigate('buyer')}>Buyer</Button>
                    <Button icon='build' onClick={() => navigate('job')}>Job Manager</Button>
                    <Button icon='cargo-ship' onClick={() => navigate('supplier')}>Supplier</Button>
                </div>

            </Card>

        </div>
    </>)
}

render(<Login />, document.getElementById('app')!)
